









































































































































































import AccessLedgerTs from './AccessLedgerTs';
export default class AccessLedger extends AccessLedgerTs {}
